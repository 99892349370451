<template>
</template>

<script>
export default {
    name: 'Refresh',
    data() {
        return {
        }
    },
    mounted() {
        if(this.$route.query.path) {
            this.$router.push({path: this.$route.query.path})
        }
    },
}
</script>